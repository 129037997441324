import React from 'react';
import './styles.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


const CaseStudyGGA = () =>  {
  return (
    <div className="App">
      <header>
        <section>
          
        </section>
        <nav className="navbar">
          <Link to="/">
          </Link>
          <ul>
            <li>
              <button className="contact-button" onClick={() => window.location.assign('mailto:cherietanzj@gmail.com')}>Contact</button>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <h1>Girl Geek Academy - weekly workshops & courses</h1>
        <section>
          <h2>Overview</h2>

        </section>
        <section>
          <p>In my role at Girl Geek Academy, my responsibilities included content creation, community moderation, assistance in teaching about various technologies, and fostering an inclusive environment for learning and friendships.</p>
          <p>We were sponsored by Microsoft to run a series of workshops online from early 2021 to November 2022. In my work, I have also assisted in the community management of our online forums on Discourse that ran in 2020 during the global pandemic when many school programs were disrupted. I helped with facilitating discussions about all things geeky, coding, 3D modelling 
          and printing, art, design, and gaming. Then, we ran over 50 workshops on general coding, Blender, AI, UI/UX, game development, art, and web design on Tulula. There was a full class every Monday!</p>

          <div className="masonry-grid">
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/8hz725islxth1ko/gga-html2.png?dl=0" alt="an episode we filmed in 2021"/>
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/ykzwlemo6yv1cqv/gga-tinkercad2.png?dl=0" alt="an episode on tinkercad that we filmed in 2021" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s//x33bi8t8gmhwadb/gga3.png?dl=0" alt="episode we filmed in 2021"  />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/jdtoneko2goixh1/gga1.png?dl=0" alt="IT conference that i helped assist in 2021" />
            </div>

            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/64sg66s2rook9ei/gga-it-conference-resized.jpg?dl=0" alt="IT conference in 2021" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/90r539ihmh6ju88/gga-conference2.jpg?dl=0" alt="IT conference that i helped assist in 2021 next to r2d2"  />
            </div>
            
          </div>

          <h2>Retrospective</h2>
          <p>My time at Girl Geek Academy led to massive growth in my skills in visual design, 3d modelling, coding, project management, and community management both online and at in-person events. 
          While working with the team, I was also inspired to continually push myself to learn and grow in my skills. 
          Moreover, it taught me valuable lessons around communication and the importance in having fun and friendship at the workplace.</p>
        </section>
       
      </main>
      <footer className="footer">
        <p>Copyright © 2022 Cherie Tan. All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default CaseStudyGGA;
