import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const ContentCreationExpanded = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="expandable-section">
      <div className="expandable-section-header">
        <a href="#" onClick={toggleExpanded}>
          {isExpanded ? ' ' : 'Read More'}
        </a>
      </div>
      {isExpanded && (
        <div className="expandable-section-body">
          <div className="columns">
            <div className="column">
              <span>
                <li><b>Skills</b></li>
                <li>Programming; Python, MicroPython, Javascript, C/C++, Lua </li>
                <li>Web development; HTML + CSS, ReactJS, Node + npm</li>
                <li>Visual design; Blender, Figma, Adobe Aftereffects, Illustrator, Photoshop</li>
                <li>3d modelling; Blender, Fusion360, Tinkercad</li>
                <li>Community management</li>
                <li>Circuit design & soldering; KiCad, through-hole soldering, SMD</li>
              </span>
            </div>
            <div className="column">
              <span>
                <li><b>Overview</b></li>
                
                <li><Link to="/casestudygga">a1. girl geek academy</Link></li>
                <li><Link to="/casestudylbe">a2. little bird electronics</Link></li>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentCreationExpanded;
