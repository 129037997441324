import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const DocumentationWritingExpanded = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="expandable-section">
      <div className="expandable-section-header">
        <a href="#" onClick={toggleExpanded}>
          {isExpanded ? ' ' : 'Read More'}
        </a>
      </div>
      {isExpanded && (
        <div className="expandable-section-body">
          <div className="columns">
            <div className="column">
              <span>
                <li><b>Skills</b></li>
                <li>Programming; Python, MicroPython, Javascript, C/C++, Lua </li>
                <li>3d printing</li>
                <li>3d modelling; Blender, Fusion360, Tinkercad</li>
                <li>Tech support</li>
                <li>Visual design; Keynote, Illustrator, Photoshop</li>
                <li>Circuit design & soldering; KiCad, through-hole soldering, SMD</li>
              </span>
            </div>
            <div className="column">
              <span>
                <li><b>Overview</b></li>
                
                <li><Link to="/casestudylbe">a1. little bird electronics</Link></li>

              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentationWritingExpanded;
