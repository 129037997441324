import React from "react";

const ImageGrid = ({ images }) => {
  return (
    <ul className='image-grid'>
      <li><img  src='https://dl.dropboxusercontent.com/s/i6q9vqk3ioe9oiz/Fractal.png?dl=0"' alt='Fractal Art i made in blender' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/kcoqm6apg9mcwwp/sierpinski%20triangle.png?dl=0"' alt='Sierpinski Triangle i made in blender' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/yk8w5cs3ofjfxiq/isometric-room.png?dl=0' alt='An isometric room i created in blender' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/imef6n40mt080ns/abstract-character.png?dl=0' alt='an abstract character i made in blender' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/w2c7ip1gf0xqlbp/bmo.jpg?dl=0"' alt='A handheld game console I made with retropie and the Raspberry Pi' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/3y2qhdkazyd9oi9/keypad.jpg?dl=0' alt='a macro keypad i created with the teensy microcontroller' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/c982ezmwv15rq8o/tictac-3d-printed.jpg?dl=0' alt='a tic tac toy i made with 3d printing' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/lv7kwkz15qdsyei/death%20watch%20process%20shot.jpg?dl=0' alt='A handheld game console I made with retropie and the Raspberry Pi' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/575f9bkoe0eowfb/diy%20mini%20nanoleaf.jpg?dl=0' alt='a diymini nanoleaf i created with the raspberry pi and 3d printing' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/tam555k22b92fjb/retropie-handheld-gamehat.jpg?dl=0' alt='A handheld game console I made with retropie, the raspberry pi, game hat, and 3d printing' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/8djdbnmp4q50d4n/content%20creation.jpg?dl=0"' alt='Content Creation work I have done, sponsored by Microsoft' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/1xtp1gp1b2an1xu/cyberpunk-led-mask.jpg?dl=0' alt='a cyberpunk styled led mask i made with the eagled and e-textiles' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/npfkjtg6c4pk6fy/etextiles-led-headgear.jpg?dl=0' alt='Fractal Art with Blender I have created' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/8trw6ecluvosz2t/it%20conference%20i%20assisted%20with.jpg?dl=0' alt='go girl go for it conference 2022 at deakin uni' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/fz32j41dmzp19jt/it%20conference%20i%20assisted%20with%20in%202022.jpg?dl=0' alt='go girl go for it conference 2022 at deakin uni' /></li>  
  </ul>
  );
};

export default ImageGrid;
