import React from 'react';
import './styles.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';


const CaseStudyLBE = () =>  {
  return (
    <div className="App">
      <header>
        <section>
          
        </section>
        <nav className="navbar">
          <Link to="/">
          </Link>
          <ul>
            <li>
              <button className="contact-button" onClick={() => window.location.assign('mailto:cherietanzj@gmail.com')}>Contact</button>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <h1>Little Bird Electronics -  electronics for hobbyists and the educational market</h1>
        <section>
          <h2>Overview</h2>
          <p>During my time at little bird electronics, i was focused on translating complex technical information into engaging content. This was to assist everyone in the customer base from school aged children to 
          adult electronics enthusiasts and teachers. Most notably I've helped create all the content published on their website for several popular products including the Raspberry Pi Zero W, Raspberry Pi 3, Raspberry Pi 4, 
          Arduino, Micro:bit, EagLED, ShakeUp, IoT products, and various electronics and robotics kits. I created over 50 guides on the Raspberry Pi, 60 guides on Micro:bit, 15 guides on MakeCode Arcade, 18 guides on E-textiles, and 40 guides on Arduino related content. 
          This content has assisted Little Bird customers and schools in topics ranging from sensors, actuators and other hardware, robotics, e-textiles and wearables.</p>
          <p>The content has been approved by the NSW Creative Kids program, and linked to from Adafruit Industries and gained recognition from the developers of MakeCode Arcade.</p>
          <p>One of the challenges was to convey the information into easily digestible chunks for total beginners to electronics and programming. To do so, this was done through writing, video, art, 
          and code with each step broken down and made as interactive as possible. The video below is an example of a single step used in a guide.</p>
        </section>
        <section>
          <video className="showcase" src="https://dl.dropboxusercontent.com/s/txlafs5y9pcystm/Pushbutton%20Module%20with%20micro_bit%20-%20P1%20to%20LED%20from%20Little%20Bird%20%28Little%20Bird%20Elect.mp4?dl=0" controls />
          <p>Within these projects, I played a huge role in building up the content for the company's website, customer satisfaction, and site ranking.</p>

          <div className="masonry-grid">
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/kpajp2l5syvx98e/lbe-automatic-plant-watering-system.png?dl=0" alt="circuit diagram of little bird uno r3 with auto plant watering system parts" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/t66g6fgo3ojibfv/shift%20register%20with%20arduino%20diagram.png?dl=0" alt="circuit diagram of little bird uno r3 with peripherals such as the shift register"/>
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/cfu8ipw9z9kzkj5/trinity%20pixel%20with%20raspberry%20pi%20example.png?dl=0"  alt="circuit diagram of raspberry pi and leds"/>
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/t5z7uwftuk9knnk/product%20example.png?dl=0"  alt="circuit diagram of raspberry pi hardware" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/myfxyn1fqmqcfc9/82780278_141864446784780_5028937490792788025_n.jpg?dl=0" alt="circuit diagram of particle photon based automatic plant watering system" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/tagsjyi367dcjj6/product%20example%203.png?dl=0"  alt="mini nanoleaf built with raspberry pi"  />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/hyvb39418are291/trinitypixels-example.jpg?dl=0" alt="coding in sublime text" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/71rztvr6le166r8/code-example-arduino.jpg?dl=0" alt="coding in arduino ide"  />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/g5xf11unbylwi1a/commandline-example.png?dl=0" alt="writing commands on the command line" />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/dnxeiyka7quwp7i/customer%20reviews3.png?dl=0" alt="customer reviews on e-textiles kit"  />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/8qifdqhbwknzqsf/customer%20reviews.png?dl=0" alt="customer reviews on micro:bit kit"  />
            </div>
            <div className="masonry-grid__item">
              <img src="https://dl.dropboxusercontent.com/s/vnaci09ijn6t3lv/customer%20reviews4.png?dl=0" alt="customer reviews on plant watering kit"  />
            </div>            
          </div>
          <p>In my work, I have also provided helpdesk support to customers and educators, which has helped the company in gaining over 2,000 Google Reviews and Product Reviews combined with an overall score of 5.0 stars.</p>
          <h2>Retrospective</h2>
          <p>My time at little bird electronics has been a great learning experience, where i grew a lot in my ability to program, create circuits, images, and videos. Equally as important were the valuable lessons around communication with 
          the customers and team to make sure we delivered the requirements. This was especially important after the global chip shortage which started in early 2020. Moreover, I also gained the valuable lesson that perfection is the enemy of good enough. </p>
        </section>
       
      </main>
      <footer className="footer">
        <p>Copyright © 2022 Cherie Tan. All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default CaseStudyLBE;
