import React, { useState } from 'react';
import './styles.css';

const ExpandableSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="expandable-section">
      <div className="expandable-section-header">
        <a href="#" onClick={toggleExpanded}>
          {isExpanded ? ' ' : 'Read More'}
        </a>
      </div>
      {isExpanded && (
        <div className="expandable-section-body">
          <div className="columns">
            <div className="column">
              <span>
                <li><b>Interests</b></li>
                <li>3d modelling</li>
                <li>Creating music</li>
                <li>Building with electronics</li>
                <li>Design</li>
                <li>Working out</li>
                <li>Travel</li>
              </span>
            </div>
            <div className="column">
              <span>
                <li><b>Timeline</b></li>
                <li>Student at Monash University (Bachelor of IT, Major in Multimedia Development), 2013 - 2015</li>
                <li>Freelance Web Developer, 2015 - 2018</li>
                <li>Creative Technologist at Little Bird Electronics, 2018 - current</li>
                <li>Creative Technologist at Girl Geek Academy, 2020 - 2022</li>
                <li>Blog Contributor at 99Designs, 2023 - 2024</li>
                <li>Student at QUT (Graduate Certificate in Education, Innovative Learning Design), Apr 2024 - Sept 2024</li>
                <li>Learning Designer at Girl Geek Academy, Aug 2024 - current</li>
              </span>
            </div>
            <div className="column">
              <span>
                <li><b>Socials</b></li>
                <li><a href="https://www.linkedin.com/in/cherietan/">Linkedin</a></li>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandableSection;
