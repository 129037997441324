import React from 'react';
import './styles.css';
import ExpandableSection from './ExpandableSection.js';
import ContentCreation from './ContentCreation.js';
import ImageGrid from './LBProjectOverview.js';
import VideoGrid from './VideoGrid.js';
import ContentCreationExpanded from './ContentCreationExpanded.js';
import DocumentationWritingExpanded from './DocumentationWritingExpanded.js';
import logo from './logo.png';
import { Link } from 'react-router-dom';



const Home = () =>  {
  return (
    <div className="App">
      <header>
        <section>
        </section>
        <nav className="navbar">
          <Link to="/">
            {/* <img src={logo} className="logo" */}

          </Link>
          <ul>
            <li>
              <button className="contact-button" onClick={() => window.location.assign('mailto:cherietanzj@gmail.com')}>Contact</button>
            </li>
          </ul>
        </nav>
      </header>
      <main>
        <section>
          
          <h1 className="landing-page-header-text">Cherie Tan</h1>
          <p>Hi, I'm Cherie. I prefer to let my work do the talking. Here’s a little info. I am a Learning Designer and Creative Technologist who creates in many mediums, from electronics, 
          mind-bending visuals, music, and writing. My work in content creation has been used in the Australian education industry as well as in a web series. I am passionate about music, art, web development, electronics and 3d printing.
          </p>
          <ExpandableSection />
        </section>
        <section>
          <h2>Work</h2>
          <h3>Learning Designer</h3>
          <p>My passion for design and technology started in University when I first encountered electronics and 3d printing. In my work, I have assisted in the community management where I moderated online forums on Discourse that ran in 2020; facilitated discussions about all things geeky, coding, 3D modelling and printing, art, design, and gaming. 
          In 2021, we were sponsored by Microsoft to run a web series. My work involved course mapping, designing course content that aligned with learning outcomes, creating and developing multimedia content, and being a speaker in our video podcast. 
          I created the workshop content which includes over 50 workshops on general coding, Blender, AI, UI/UX, game development, art, and web design. The workshops ran from 2020 - 2022, with a full class every Monday.</p>
          <ContentCreationExpanded />
          <ContentCreation />
          <h3>Creative Technologist & Maker</h3>
          <p>Created Little Bird’s online instructional content which includes over 50 guides on the Raspberry Pi, 60 guides on Micro:bit, 18 guides on E-textiles, and 40 guides on Arduino related content. This content has assisted Little Bird customers and schools in topics ranging from sensors, actuators and other hardware, robotics, e-textiles and wearables. The Little Bird Micro:bit Project Kits have also been approved by the NSW Creative Kids program. This is a government funded program that helps school-aged kids (4.5 to 18 years) to get involved in creative and cultural activities. Also created a set of 15 instructional guides for game programming on MakeCode Arcade. The content has been linked to from Adafruit Industries and gained recognition from the developers of MakeCode Arcade. In addition, I have also provided helpdesk support to customers and educators, which has helped the company in gaining over 2,000 Google Reviews and Product Reviews combined with an overall score of 5.0 stars.</p>
          <DocumentationWritingExpanded />
          <ImageGrid />
          <h2>Other Projects</h2>
          <h3>Music and VJ Loops</h3>
          <p>During the lockdowns, I started to make electronic music in ableton live. I also learned to make 3d art with the help of a few wonderful creators on youtube. Enjoy!</p>
          <VideoGrid />
        </section>
       
      </main>
      <footer className="footer">
        <p>Copyright © 2022 Cherie Tan. All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default Home;
