import React from "react";

const ImageGrid = ({ images }) => {
  return (
    <ul className='image-grid'>
      <li><img  src='https://dl.dropboxusercontent.com/s//rkmfmoiumadfckk/8bit-sprites-and-animation-with-pico-8.png?dl=0' alt='tutorial i created on pico-8' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/c9wctx3nfk1znlm/intro%20to%20pico-8.png?dl=0' alt='tutorial series i created for pico-8 on raspberry pi' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/jb9765g22nf9706/meet-the-microbit.png?dl=0' alt='tutorial series on the micro:bit i created' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/jabio8dwp6ypjap/2d-sidescrolling-platformer-with-makecode-arcade.png?dl=0' alt='tutorial series i created for makecode arcade' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/lyifevc02cgkgyk/laser%20head%20sensor%20and%20microbit.png?dl=0' alt='tutorial series i created for makecode arcade' /></li>
      <li><img  src='https://dl.dropboxusercontent.com/s/pnk1xjgsufil297/getting-started-with-raspberry-pi3b.png?dl=0' alt='tutorial series i created for the raspberry pi' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/zicnz8busi7v8io/endless%20space%20shooter%20with%20raspberry%20pi.png?dl=0' alt='tutorial series i created for pico-8 on raspberry pi' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/aqds70xysowju0i/handheld%20retropie%20with%20raspberry%20pi.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/c0zcrz8n5ju1im7/sense%20hat%20with%20raspberry%20pi.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/wd4kulh0c5jgojp/pacman%20and%20shakeup.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/7iwkdjrl2zpvscz/i2c%20and%20arduino.png?dl=0' alt='tutorial series i created on the arduino' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/x09hf6lnlc4e0t4/uart%20and%20arduino.png?dl=0' alt='tutorial series i created about the arduino' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/i0cmlu1ls1fwpmp/shift-register-and-arduino.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/mxv7bdaohsuvsvt/automatic-plant-watering-arduino.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
      <li><img src='https://dl.dropboxusercontent.com/s/dxf924lxkmx7e8i/getting%20started%20with%20eagled.png?dl=0' alt='tutorial series i created for the eagled e-textiles development kit' /></li>
  </ul>
  );
};

export default ImageGrid;
