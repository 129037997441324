import React from 'react';

class VideoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [
        {
          id: 1,
          title: 'Video 1',
          src: 'https://dl.dropboxusercontent.com/s/vgagrp5ok99owmh/The%20Sun%20Animated%203500Kb%20per%20second.mp4?dl=0'
        },
        {
          id: 2,
          title: 'Video 2',
          src: 'https://dl.dropboxusercontent.com/s/jxkcp3bhp6umi7x/shadow%20final.mp4?dl=0'
        }
      ]
    };
  }

  render() {
    return (
        
      <div className="video-grid">

        {this.state.videos.map(video => (
          <div key={video.id} className="video">

            <video src={video.src} controls />
          </div>
        ))}
      </div>
    );
  }
}

export default VideoGrid;
