import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CaseStudyGGA from './components/casestudygga.js';
import CaseStudyLBE from './components/casestudylbe.js';


import Home from './components/Home'


const App = () => {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/casestudygga" element={<CaseStudyGGA/>}/>
          <Route exact path="/casestudylbe" element={<CaseStudyLBE/>}/>

        </Routes>
    </Router>
  );
}

export default App;